import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme, { slidesLength?: number }>((theme) =>
  createStyles({
    slide: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '320px',
      },
      [theme.breakpoints.down('md')]: {
        backfaceVisibility: 'hidden',
        margin: '0',
        position: 'absolute',
        transition: 'all 0.1s linear',
      //  width: 'calc(100% - 24px)',
        width: ({ slidesLength }) => slidesLength ? `calc(100% - ${(slidesLength-1)*12}px)` : `calc(100% - 24px)` ,
      },
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      height: '100%',
      width: 'calc(33.33% - 16px)',
    },
    slideImage: {
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '132%',
        position: 'relative',
        width: '100%',
      },
      width: '100%',
      zIndex: -1,
    },
    slideLink: {
      textDecoration: 'none',
    },
    slideName: {
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      alignItems: 'flex-end',
      background: 'linear-gradient(357.68deg, rgba(0, 0, 0, 0.6) 2.66%, rgba(0, 0, 0, 0) 88%)',
      color: ColorsPalette.neutral2,
      display: 'flex',
      flexDirection: 'row',
      height: 80,
      marginTop: -80,
      padding: '49px 18px 15px 13px',
      //  padding: '0 18px 15px 13px',
      ...TypographyCollection.HeadingMobileXS,
    },
    slideNameArrowIcon: {
      '& path': {
        fill: ColorsPalette.neutral2,
      },
      height: 16,
      marginLeft: 'auto',
      width: 18,
    },
    slideNameDenimIcon: {
      '& path': {
        fill: ColorsPalette.neutral2,
      },
      height: 16,
      marginRight: 13,
      width: 18,
    },
  }));
