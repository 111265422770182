import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '1064px',
      padding: '0 16px',
      width: '100%',
    },
  }));

