import React from 'react';

export interface IImageLazyLoadContext {
  delayTime?: number;
  threshold?: number;
  visibleByDefault?: boolean;
}

const defaultValues: IImageLazyLoadContext = {
  delayTime: 0, threshold: 800, visibleByDefault: false,
};

export const ImageLazyLoadContext = React.createContext<IImageLazyLoadContext>(defaultValues);
