import React from 'react';

import Link from '@components/common/Link';
import PictureShow from '@components/common/PictureShow';
import { ICarouselItem } from '@components/pages/home/carousel/CarouselItem';
import { miniSite } from '@constants/config';
import ArrowForwardIcon from '@icons/ArrowForward';
import DenimIcon from '@icons/Denim';

import styles from './styles';

export interface ICustomCardProps {
  item: ICarouselItem;
  slidesLength?: number;
}

const CustomCardBody: React.FunctionComponent<ICustomCardProps> = ({ item, slidesLength }) => {
  const classes = styles({ slidesLength });

  return (
    <Link legacyBehavior href={item.url}>
      <a className={classes.slideLink}>
        <PictureShow className={classes.slideImage} src={item.image || '/images/emptyCard.png'} />
        <div className={classes.slideName}>
          {!miniSite && (
            <DenimIcon className={classes.slideNameDenimIcon} />
          )}
          <span>{item.title}</span>
          <ArrowForwardIcon className={classes.slideNameArrowIcon} />
        </div>
      </a>
    </Link>
  );
};

export default CustomCardBody;
