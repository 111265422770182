export const zPosition = (value: number, slidesLength: number): number => {
  const valueBase = Math.abs(value);
  const result = valueBase < 0.5 ? 0 : (valueBase * 10 * slidesLength * -1);

  return result;
};

export const xPosition = (value: number): number => {
  const valueDirection = Math.sign(value);
  const valueBase = Math.abs(value);
  const result = ((-4.022 * Math.pow(valueBase, 2)) + (4.025 * valueBase));
  if (result > 1) {
    return 1 * valueDirection;
  } else if (result < 0) {
    return 0;
  }

  return valueDirection * result;
};

export const yPosition = (value: number, slidesLength: number): number => {
  const valueBase = Math.abs(value);
  const result = valueBase < 0.5 ? 0 : (valueBase * 10 * slidesLength * -1);

  return result;
};

export const rotatePosition = (value: number): number => {
  const valueDirection = Math.sign(value);
  const valueBase = Math.abs(value);
  const valueFunc = xPosition(valueBase);
  const rotate = valueFunc * 30;
  const result = rotate > 30 ? 30 : rotate;

  return result * valueDirection;
};

export const unify = (e) => {
  return e.changedTouches ? e.changedTouches[0] : e;
};
