import React from 'react';

import { ICarouselItem } from '@components/pages/home/carousel/CarouselItem';
import CustomCardBody from '@components/pages/home/customСards/Card/cardBody';

import styles from './styles';

export interface ICustomCardProps {
  item: ICarouselItem;
  slidesLength?: number;
  transform?: string;
}

const CustomCard: React.FunctionComponent<ICustomCardProps> = ({ item, slidesLength, transform }) => {

  const classes = styles({ slidesLength });

  return (
    <div className={classes.slide} style={transform ? { transform } : {}}>
          <CustomCardBody slidesLength={slidesLength} item={item} />
    </div>
  );
};

export default CustomCard;
