import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';


export default makeStyles((theme) =>
  createStyles({
    picture: {
      '& img, & video': {
        border: 0,
        cursor: 'pointer',
        height: '100%',
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      '& span': {
        display: 'flex !important',
      },
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '152%',
        position: 'relative',
        width: '100%',
      },
    //  display: 'block',
      position: 'relative',
      width: '100%',
    },
    pictureBackGround: {
      backgroundColor: ColorsPalette.neutral1,
    },
  }),
);
