import classNames from 'classnames';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ProductVideo from '@components/pages/product/product_detail/ProductVideo';
import { ImageLazyLoadContext } from 'context/lazyLoad';

import styles from './styles';

export interface IPictureShow {
  alt?: string;
  className?: string;
  isActive?: boolean;
  isFullScreenMode?: boolean;
  isVideo?: boolean;
  onClick?: () => void;
  onLoaded?: () => void;
  onTouchEnd?: (e?) => void;
  onTouchMove?: (e?) => void;
  src: string;
  useLazyProperty?: boolean;
}

const PictureShow: React.FunctionComponent<IPictureShow> = ({
  alt,
  className,
  isActive = false,
  isFullScreenMode = false,
  isVideo = false,
  onClick,
  onLoaded,
  onTouchEnd,
  onTouchMove,
  src,
  useLazyProperty= false,
}) => {
  const classes = styles();


  return src ? (
    <div className={classNames(classes.picture, className, { [classes.pictureBackGround]: !isVideo, ['swiper-zoom-container']: isFullScreenMode  })}
         onClick={onClick}>
      {isVideo ? (
        <ProductVideo src={src} controls={true} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} isActive={isActive} />
      ) : (
        <ImageLazyLoadContext.Consumer>
          {(settings) => (
            <LazyLoadImage
              afterLoad={onLoaded}
              alt={alt}
              delayTime={settings.delayTime}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              src={src}
              threshold={settings.threshold}
              visibleByDefault={settings.visibleByDefault}
              placeholder={useLazyProperty ? <img alt={alt} src={src} loading="lazy" /> : undefined}
            />
          )}
        </ImageLazyLoadContext.Consumer>
      )}
    </div>
  ) : null;
};
export default PictureShow;
