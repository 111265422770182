import classNames from 'classnames';
import React from 'react';

import { ICarouselItem } from '@components/pages/home/carousel/CarouselItem';
import Card from '@components/pages/home/customСards/Card';
import { IHomePageComponentDataObject } from '@interfaces/home';

import styles from './styles';

export interface ICustomCardsProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const SwipableDesktop: React.FunctionComponent<ICustomCardsProps> = ({ component, className }) => {
  const { customcards, type } = component;
  const filterDesktopItems = ({ showOnlyMobileAndApp }: ICarouselItem) => !showOnlyMobileAndApp;
  const items: ICarouselItem[] = (customcards?.filter(filterDesktopItems)?.slice(0, 3) || []);
  const slidesLength = items.length || 0;
  const classes = styles({ slidesLength });

  return (
    <div className={classNames(classes.row, className)}>
      {items.map((item, index) => {
        return (
          <Card key={`${type || 'cardCustom'}-${index}-x`} item={item} />
        );
      })}
    </div>);
};

export default SwipableDesktop;
