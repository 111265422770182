import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

export default makeStyles((theme) =>
  createStyles({
    progress: {
      backgroundColor: '#d4d4d4',
      borderRadius: '10px',
      height: 4,
      margin: '20px auto 10px auto',
      maxWidth: 179,
      width: '100%',
    },
    progressBar: {
      backgroundColor: ColorsPalette.primary,
      borderRadius: '10px',
      height: '100%',
      transition: 'all 0.3s linear',
    },
    swiper: {
      cursor: 'grab',
      margin: '0 20px',
      overflow: 'visible',
      transformStyle: 'preserve-3d',
    },
    swiperWrapper: {
      aspectRatio: 0.8,
      paddingBottom: '120%',
      width: '100%',
    },
    swiperWrapperLock: {
      touchAction: 'pan-x',
    },
  }));
