import React, { useEffect, useRef, useState } from 'react';

import { mobileMode } from '@constants/config';

interface IProductVideoProps {
  autoPlay?: boolean;
  controls?: boolean;
  isActive?: boolean;
  src: string;
  onTouchMove?: () => void;
  onTouchEnd?: () => void;
}

const ProductVideo: React.FunctionComponent<IProductVideoProps> = ({ isActive, ...videoProps}) => {
  const [playedTwoTimes, setPlayedTwoTimes] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(()=>{
    if(mobileMode && isActive && videoRef.current){
      videoRef.current.focus();
      videoRef.current.blur();
    }
  }, [videoRef, isActive]);

  const endedVideoHandler = () => {
    if (!playedTwoTimes) {
      setPlayedTwoTimes(true);
      videoRef?.current?.play();
    } else {
      setPlayedTwoTimes(false);
    }
  };

  const launchIntoFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  };

  const playVideoHandler = () => {
    if (mobileMode && videoRef?.current && !playedTwoTimes) {
      launchIntoFullscreen(videoRef.current);
    }
  };

  return <video ref={videoRef} onEnded={endedVideoHandler} onPlay={playVideoHandler} {...videoProps} />;
};

export default ProductVideo;
