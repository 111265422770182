import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ICarouselItem } from '@components/pages/home/carousel/CarouselItem';
import {
  rotatePosition, unify,
  xPosition,
  yPosition,
  zPosition,
} from '@components/pages/home/customСards/Mobile/utils';
import useScrollBlock from '@hooks/scrollBlock';
import { IHomePageComponentDataObject } from '@interfaces/home';
import Card from './../Card';

import styles from './styles';

export interface ICustomCardsProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

let stepTime = 0;
let x0 = 0;
let lastX = 0;
let y0 = 0;
let isScrolled: boolean | null = null;
let isDraged: boolean | null = null;
let locked = false;

const SwipeableMobile: React.FunctionComponent<ICustomCardsProps> = ({ component, className }) => {
  const { customcards, type } = component;
  const items: ICarouselItem[] = (customcards || []);
  const slidesLength = items.length || 0;
  const classes = styles();

  const [activeSlide, changeActiveSlide] = useState(0);
  const [touched, changeTouched] = useState(false);

  const [lastXDirection, changeXDirection] = useState<number>(0);
  const [lastXPosition, changeLastXPosition] = useState<number>(0);
  const [prevXPosition, changePrevXPosition] = useState<number>(0);
  const [screenWidth, changeScreenWidth] = useState<number>(360);
  const [swipeProgress, changeSwipeProgress] = useState<number>(0);

  const { blockScroll: block, allowScroll: allow } = useScrollBlock();

  const swiperRef = useRef<HTMLDivElement | null>(null);
  const slidePosition = (slideIndex: number): number => {
    const position = slideIndex - activeSlide;
    if (position < 0) {
      return position + slidesLength;
    }
    return position;
  };

  const preventTouchmove = (event) => {
    event.preventDefault();
  };

  const blockScroll = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current?.addEventListener('touchmove', preventTouchmove);
    }
    block();
  };

  const allowScroll = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current?.removeEventListener('touchmove', preventTouchmove);
    }
    allow();
  };
  // const even = (n) => !(n % 2);

  const slideTranslate = (slidePositionIndex: number): string => {
    if (slidePositionIndex === 0) {
      return locked ? `translate3d(calc(0px + ${xPosition(swipeProgress) * 170}%), ${yPosition(swipeProgress, slidesLength)}px, ${zPosition(swipeProgress, slidesLength)}px) rotateZ(${rotatePosition(swipeProgress)}deg) scale(1)` : 'translate3d(0px, 0px, 0px) rotateZ(0deg) scale(1)';
    } else {
      //   return `translate3d(${even(slidePositionIndex) ? `16px` : `24px`}, ${slidePositionIndex * -24}px, ${slidePositionIndex * -10}px) rotateZ(0deg) scale(1)`;
      return `translate3d(${slidePositionIndex * 12}px, ${slidePositionIndex * -12}px, ${slidePositionIndex * -10}px) rotateZ(0deg) scale(1)`;
    }
  };

  const endTransition = () => {
    if (swipeProgress < 0.98 && swipeProgress > -0.98) {
      const swipeDirection = Math.sign(swipeProgress);
      changeSwipeProgress((Math.abs(swipeProgress) + 0.1) * swipeDirection);

      const endProgress = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].filter((step) => step > (Math.abs(swipeProgress) + 0.1));
      for (const newPo of endProgress) {
        setTimeout(() => {
          changeSwipeProgress(newPo * swipeDirection);
        }, 700 * newPo);
      }
      setTimeout(() => {
        changeActiveSlide(activeSlide + 1 > slidesLength - 1 ? 0 : activeSlide + 1);
        x0 = 0;
        y0 = 0;
        locked = false;
        isDraged = null;
        changeSwipeProgress(0);
      }, endProgress.length * 100);
    } else {
      changeActiveSlide(activeSlide + 1 > slidesLength - 1 ? 0 : activeSlide + 1);
      x0 = 0;
      y0 = 0;

      isScrolled = null;
      locked = false;
      isDraged = null;
      changeSwipeProgress(0);
    }
    allowScroll();
  };

  const endTransitionBack = () => {
    const swipeDirection = Math.sign(swipeProgress);

    changeSwipeProgress((Math.abs(swipeProgress) - 0.1) * swipeDirection);
    const endProgress = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].filter((step) => step < (Math.abs(swipeProgress) - 0.1));
    for (const newPo of endProgress) {
      setTimeout(() => {
        changeSwipeProgress(newPo * swipeDirection);
      }, 700 * newPo);
    }
    setTimeout(() => {
      x0 = 0;
      y0 = 0;
      isScrolled = null;
      locked = false;
      isDraged = null;
      changeSwipeProgress(0);
    }, endProgress.length * 100);
    allowScroll();
  };

  const lock = (e) => {
    changeTouched(true);
    changeSwipeProgress(0);
    x0 = unify(e).clientX;
    y0 = unify(e).clientY;
    locked = true;
  };

  useEffect(() => {
    if (locked) {
      const lastSwipeDirection = Math.sign(lastXPosition - prevXPosition);
      changeXDirection(lastSwipeDirection);
      changePrevXPosition(lastXPosition);
    }
  }, [lastXPosition]);

  const getTouchAngel = (distanceY, distanceX) => {
    return (Math.atan2(Math.abs(distanceY), Math.abs(distanceX)) * 180) / Math.PI;
  };

  const drag = (e) => {
    if (locked) {
      const cursorXPosition = unify(e).clientX;
      const cursorYPosition = unify(e).clientY;
      const currentTime = new Date().getTime();
      const timeDist = currentTime - stepTime;
      const stepX = cursorXPosition - lastX;
      if (timeDist > 250 || stepX > 30 || stepX < -30) {
        stepTime = currentTime;
        lastX = cursorXPosition;
        changeLastXPosition(cursorXPosition);
        const distanceX = cursorXPosition - x0;
        const distanceY = cursorYPosition - y0;
        const touchAngle = getTouchAngel(distanceY, distanceX);
        if (touchAngle < 45) {
          if (!isScrolled) {
            isDraged = true;
            blockScroll();
            isScrolled = false;
            e.preventDefault();
            e.stopPropagation();
            const xProgress = +(distanceX / screenWidth).toFixed(2);
            changeSwipeProgress(xProgress);
          }
        } else {
          if (isScrolled === null && !isDraged) {
            isScrolled = true;
            isDraged = false;
            e.preventDefault();
            e.stopPropagation();
          } else if (!isScrolled) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      } else {
       // e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  const move = (e) => {
    if (locked) {
      if (Math.sign(lastXDirection) === Math.sign(swipeProgress) && Math.abs(swipeProgress) > 0.1) {
        endTransition();
      } else {
        endTransitionBack();
      }
    }
    changeTouched(false);
  };

  const size = () => {
    changeScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    size();
  }, []);

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);
  /*
    useEffect(() => {
      if (swiperRef.current) {
        swiperRef.current.addEventListener('mousemove', drag, { capture : true, passive: false });
        swiperRef.current.addEventListener('touchmove', drag, { capture : true, passive: false });
      }
      return () => {
        if (swiperRef.current) {
          swiperRef.current.removeEventListener('mousemove', drag);
          swiperRef.current.removeEventListener('touchmove', drag);
        }
      };
    }, [swiperRef.current]);
  */
  return (
    <>
      <div className={classNames(classes.swiperWrapper, { [classes.swiperWrapperLock]: touched && swipeProgress !== 0 })}
           style={{ paddingTop: `${(slidesLength - 1) * 12}px` }}>
        <div ref={swiperRef} className={classes.swiper}
             onMouseDown={lock}
             onTouchStart={lock}
             onMouseMove={drag}
             onTouchMove={drag}
             onMouseUp={move}
             onTouchEnd={move}
        >
          {items.map((item, index) => {
            const slideStackPosition = slidePosition(index);
            return (<Card key={`${type || 'cardCustom'}-${index}-x`} item={item} slidesLength={items.length || 1}
                          transform={slideTranslate(slideStackPosition)} />);
          })}
        </div>
      </div>
      <div className={classNames(classes.progress, className)}>
        <div className={classes.progressBar} style={{ width: `${(activeSlide + 1) / slidesLength * 100}%` }} />
      </div>
    </>
  );
};

export default SwipeableMobile;
